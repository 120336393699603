import * as React from "react";
import { Filter, TextInput } from 'react-admin';
import { List, Datagrid, TextField, DateField, useLocaleState } from 'react-admin';
import { AreaEdit } from "./area.edit.component";

const AreaListFilter = props => (
    <Filter {...props}>
        <TextInput source="search" alwaysOn resettable />
    </Filter>
);

export const AreaList = () => {
    const [locale] = useLocaleState();

    return (
        <List
            filters={<AreaListFilter />}
            exporter={false}
        >
            <Datagrid rowClick="edit" expand={<AreaEdit/>}>
                <TextField source={`name.${locale}`} label="resources.areas.fields.name"/>
                <DateField source="createdAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
                <DateField source="updatedAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
            </Datagrid>
        </List>
    );
}