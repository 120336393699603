import * as React from "react";
import { Admin, Resource } from "react-admin";
import { QueryClient } from "react-query";
import authProvider from "./authProvider";
// Components
import { Dashboard } from "./components/dashboard/dashboard.component";
import Layout from "./layouts/layout";
// Pages
import LoginPage from "./pages/login.page";
import routes from "./routes";

import { lightTheme } from "./theme";
import { dataProvider } from "./dataProvider";
import { i18nProvider } from './i18nProvider';

const queryClient = new QueryClient({
  defaultOptions: {
      queries: {
          staleTime: 5 * 60 * 1000, // 5 minutes
      },
  },
});

const App = () => {
  return (
    <Admin
      title="Admin MiQMAP"
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      queryClient={queryClient}
      dashboard={Dashboard}
      layout={Layout}
      disableTelemetry={true}
      // menu={Menu}
      // catchAll={NotFound}
      theme={lightTheme}
      loginPage={LoginPage}
    >
      {(permissions) => routes(permissions)}
    </Admin>
  );
};

export default App;
