import * as React from 'react';
import { forwardRef } from 'react';
import { UserMenu as RAUserMenu, useUserMenu, Logout } from 'react-admin';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';

export const ConfigurationMenu = forwardRef((props, ref: any) => {
    const { onClose } = useUserMenu();

    return (
        <MenuItem
            ref={ref}
            {...props}
            component={Link}
            to="/configuration"
            onClick={onClose}
        >
            <ListItemIcon>
                <SettingsIcon/>
            </ListItemIcon>
            <ListItemText>
                Configuration
            </ListItemText>
        </MenuItem>
    );
});

export const UserMenu = () => (
    <RAUserMenu>
        <ConfigurationMenu/>
        <Logout/>
    </RAUserMenu>
);

export default UserMenu;
