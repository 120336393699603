import { DateInput, DeleteButton, Edit, NumberInput, required, SaveButton, SimpleForm, TextInput, Toolbar, useRecordContext, TranslatableInputs, useTranslate } from "react-admin";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { locales } from '../../../i18n/languges';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues } from 'react-hook-form';

const useStyles = makeStyles({
    inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

const EditEntriesButton = () => {
    const record = useRecordContext();
    const translate = useTranslate();

    return (
        <Button
            component={Link}
            to={`/startpages/rows/${record.id}/entries`}
            startIcon={<EditIcon />}
        >
            {translate('resources.startpages.editRowEntries')}
        </Button>
    )
};

const EditToolbar = () => (
    <Toolbar>
        <SaveButton />
        <DeleteButton redirect="/startpages/1/rows" />
    </Toolbar>
);

export const StartPageRowEdit = () => {
    const classes = useStyles();

    const record = useRecordContext();
    const { rowId } = useParams();
    const id = record?.id ?? rowId;

    const schema = yup
        .object()
        .shape({
            title: yup.object().shape({
                en: yup.string().required()
            }).required(),
        });
    const resolver = yupResolver<FieldValues>(schema);

    return (
        <Edit resource="startpages/rows" id={id} redirect="/startpages/1/rows">
            <SimpleForm toolbar={<EditToolbar />} resolver={resolver}>
                <TranslatableInputs locales={locales} >
                    <TextInput source="title"  label="resources.startpages.fields.title"/>
                </TranslatableInputs>
                <NumberInput source="priority" label="resources.startpages.fields.priority"/>

                <EditEntriesButton />
            </SimpleForm>
        </Edit>
    );
};