import * as React from "react";
import { Filter, TextInput } from 'react-admin';
import { List, Datagrid, TextField, BooleanField, DateField, useLocaleState } from 'react-admin';
import { AreaOfInterestCategoryEdit } from "./aoiCategory.edit.component";

const AreaOfInterestCategoryFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
    </Filter>
);

export const AreaOfInterestCategoryList = () => {
    const [locale] = useLocaleState();

    return (
        <List
            filters={<AreaOfInterestCategoryFilter />}
            exporter={false}
            actions={false}
        >
            <Datagrid rowClick="edit" expand={<AreaOfInterestCategoryEdit/>} bulkActionButtons={false}>
                <BooleanField source="isListed" />
                <TextField source={`name.${locale}`} label="resources.aoicategories.fields.name"  />
                <TextField source={`pageTitle.${locale}`} label="resources.aoicategories.fields.pageTitle" />
                <DateField source="createdAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
                <DateField source="updatedAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
            </Datagrid>
        </List>
    );
}