import polyglotI18nProvider from 'ra-i18n-polyglot';
import en from './i18n/en';
import de from './i18n/de';
import sv from './i18n/sv';


const translations = { en, de, sv };

export const i18nProvider = polyglotI18nProvider(
    locale => translations[locale],
    'en', // default locale
    [
        { locale: 'en', name: 'English' }, 
        { locale: 'de', name: 'German' },
        { locale: 'sv', name: 'Swedish' }
    ],
    { allowMissing: true }
);
