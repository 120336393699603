import { makeStyles } from '@mui/styles';
import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  SelectInput,
  ImageField,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  FileInput,
  FileField,
  required,
  number,
  DateTimeInput,
} from "react-admin";
import { Status } from "../../../shared/enums/status.enum";
import { Zone } from "./enums/zone.enum";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const SubPathCreate = () => {
  const zoneOptions = Object.keys(Zone).map((key) => ({
    id: Zone[key],
    name: key,
  }));

  const statusOptions = Object.keys(Status).map((key) => ({
    id: Status[key],
    name: key,
  }));

  return (
    <Create>
      <SimpleForm sanitizeEmptyValues>
        <TextInput source="start" label="start number" />
        <TextInput source="end" label="end number" />
        <DateTimeInput
          source="groomedAt"
          defaultValue={new Date()}
          validate={[required()]}
        />
        <SelectInput
          source="zone"
          validate={[required()]}
          choices={zoneOptions}
        />
        <ReferenceArrayInput source="area_ids" reference="areas">
          <SelectArrayInput optionText="name" label="Areas" validate={[required()]} defaultValue={[]} />
        </ReferenceArrayInput>
        <SelectInput
          source="status"
          validate={[required()]}
          defaultValue={"open"}
          choices={statusOptions}
        />
        <FileInput
          source="gpx"
          label="GPX file"
          accept=".gpx"
          validate={[required()]}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
