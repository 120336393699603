import { Create, NumberInput, SimpleForm, TextInput, TranslatableInputs } from "react-admin";
import { makeStyles } from "@mui/styles";
import { locales } from '../../../i18n/languges';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues } from 'react-hook-form';

const useStyles = makeStyles({
    inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

const schema = yup.object().shape({
    title: yup.object().shape({
        en: yup.string().required()
    }).required(),
});

export const StartPageRowCreate = () => {
    const classes = useStyles();
    const resolver = yupResolver<FieldValues>(schema);

    return (
        <Create resource="startpages/rows" redirect="/startpages/1/rows">
            <SimpleForm resolver={resolver} >
                <TranslatableInputs locales={locales} sx={{marginBottom: '2rem'}}>
                    <TextInput source="title" label="resources.startpages.fields.title"/>
                </TranslatableInputs>
                <NumberInput source="priority" label="resources.startpages.fields.priority" className={classes.inlineBlock} />

                <TextInput readOnly source="pageId" defaultValue={1} style={{display:'none'}}/>
            </SimpleForm>
        </Create>
    );
};
