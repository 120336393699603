import { makeStyles } from '@mui/styles';
import * as React from "react";
import { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  required,
  SelectInput,
  DateTimeInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  useGetOne,
  ReferenceArrayInput,
  SelectArrayInput,
  useRecordContext,
  TranslatableInputs,
  useLocaleState
} from "react-admin";
import { CustomToolbar } from "../../components/customtoolbar/customToolbar.component";
import { PublishingStatus } from "../../shared/enums/publishing-status.enum";
import { MapConnection } from "./enums/map-connection.enum";
import { Recurrence } from "./enums/recurrence.enum";
import { Severity } from "./enums/severity.enum";
import { validateInformation } from "./services/validate-information.service";
import { useParams } from 'react-router-dom';
import { locales } from '../../i18n/languges';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues } from 'react-hook-form';

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const InformationEdit = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [locale] = useLocaleState();
  const record = useRecordContext();
  const information = useGetOne("information", { id: id ?? record?.id });
  const [mapConnectionValue, setMapConnectionValue] = useState(
    MapConnection.None
  );

  const mapConnectionOptions = Object.keys(MapConnection).map((key) => ({
    id: MapConnection[key],
    name: `resources.enums.mapConnection.${key}`,
  }));

  const statusOptions = Object.keys(PublishingStatus).map((key) => ({
    id: PublishingStatus[key],
    name: `resources.enums.publishingStatus.${key}`,
  }));

  const severityOptions = Object.keys(Severity).map((key) => ({
    id: Severity[key],
    name: `resources.enums.severity.${key}`,
  }));

  const recurrenceOptions = Object.keys(Recurrence).map((key) => ({
    id: Recurrence[key],
    name: `resources.enums.recurrence.${key}`,
  }));

  const schema = yup
    .object()
    .shape({
      title: yup.object().shape({
        en: yup.string().required()
      }).required(),
      description: yup.object().shape({
        en: yup.string().required()
      }).required()
    });
  const resolver = yupResolver<FieldValues>(schema);

  useEffect(() => {
    if (!information.isLoading) {
      setMapConnectionValue(information?.data?.mapConnection);
    }
  }, [information.isLoading]);

  return (
    <Edit id={record?.id}>
      <SimpleForm
        toolbar={<CustomToolbar />}
        validate={validateInformation}
        resolver={resolver}
      >
        <SelectInput
          source="status"
          validate={[required()]}
          choices={statusOptions}
        />
        <SelectInput
          source="severity"
          validate={[required()]}
          choices={severityOptions}
        />
        <TranslatableInputs fullWidth={true} locales={locales} sx={{ marginBottom: '2rem' }} >
          <TextInput source={"title"} fullWidth />
          <TextInput source={"buttonText"} />
          <TextInput
            source={"description"}
            rows={8}
            multiline={true}
            fullWidth={true}
          />
        </TranslatableInputs>
        <DateTimeInput source="startAt" defaultValue={new Date()} />
        <DateTimeInput source="endAt" />
        <TextInput source="buttonUrl" />

        <ReferenceArrayInput source="tag_ids" reference="informationtags">
          <SelectArrayInput optionText={`name.${locale}`} label="Tags" />
        </ReferenceArrayInput>

        <SelectInput
          source="mapConnection"
          choices={mapConnectionOptions}
          onChange={(e) =>
            setMapConnectionValue(e.target.value as MapConnection)
          }
          validate={[required()]}
        ></SelectInput>
        {mapConnectionValue === MapConnection.PointOfInterest && (
          <ReferenceInput
            source="pointOfInterestId"
            reference="pois"
          >
            <AutocompleteInput
              optionText="name"
              label="Point of Interest"
              suggestionLimit={10} /*matchSuggestion={() => true}*/
            />
          </ReferenceInput>
        )}
        {mapConnectionValue === MapConnection.AreaOfInterest && (
          <ReferenceInput
            source="areaOfInterestId"
            reference="aois"
          >
            <AutocompleteInput
              optionText="name"
              label="Area of Interest"
              suggestionLimit={10} /*matchSuggestion={() => true}*/
            />
          </ReferenceInput>
        )}
        {(mapConnectionValue === MapConnection.PointOfInterest ||
          mapConnectionValue === MapConnection.AreaOfInterest) && (
            <NumberInput source="radius" label="radius (m)" />
          )}
        <SelectInput
          source="recurrence"
          validate={[required()]}
          choices={recurrenceOptions}
        />
        <DateInput
          disabled
          source="createdAt"
          formClassName={classes.inlineBlock}
        />
        <DateInput
          disabled
          source="updatedAt"
          formClassName={classes.inlineBlock}
        />
      </SimpleForm>
    </Edit>
  );
};
