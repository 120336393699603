import * as React from "react";
import { makeStyles } from '@mui/styles';
import { Create, SimpleForm, TextInput, required, SelectInput, AutocompleteArrayInput, ReferenceArrayInput, FormDataConsumer } from 'react-admin';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export const FilterCreate = () => {
    return (
        <Create>
            <SimpleForm sanitizeEmptyValues>
                <TextInput source="name" validate={[required()]} fullWidth/>
                <SelectInput source="filterType" validate={[required()]} defaultValue={'path'} fullWidth choices={[
                    { id: 'path', name: 'resources.grooming/filter/paths.fields.crossCountry' },
                    { id: 'subPath', name: 'resources.grooming/filter/paths.fields.snowmobile' },
                ]} />
                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) => formData.filterType === 'path' &&
                        <ReferenceArrayInput reference="grooming/paths" source="path_ids" fullWidth>
                            <AutocompleteArrayInput optionText="name" suggestionLimit={10} defaultValue={[]} />
                        </ReferenceArrayInput>
                    }
                </FormDataConsumer>
                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) => formData.filterType === 'subPath' &&
                        <ReferenceArrayInput reference="grooming/snowmobile/subpaths" source="subPath_ids" fullWidth>
                            <AutocompleteArrayInput optionText="name" suggestionLimit={10} defaultValue={[]} />
                        </ReferenceArrayInput>
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
}