import { makeStyles } from '@mui/styles';
import * as React from "react";
import {
  DateInput,
  DateTimeInput,
  Edit,
  FileField,
  FileInput,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from "react-admin";
import { Status } from "../../../shared/enums/status.enum";
import { CustomToolbar } from "./../../../components/customtoolbar/customToolbar.component";
import { Zone } from "./enums/zone.enum";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const SubPathEdit = () => {
  const classes = useStyles();
  const zoneOptions = Object.keys(Zone).map((key) => ({
    id: Zone[key],
    name: key,
  }));

  const statusOptions = Object.keys(Status).map((key) => ({
    id: Status[key],
    name: key,
  }));

  const record = useRecordContext();

  return (
    <Edit id={record?.id}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source="start" label="start number" />
        <TextInput source="end" label="end number" />
        <DateTimeInput
          source="groomedAt"
          defaultValue={new Date()}
          validate={[required()]}
        />
        <SelectInput
          source="zone"
          validate={[required()]}
          choices={zoneOptions}
        />
        <ReferenceArrayInput source="area_ids" reference="areas">
          <SelectArrayInput optionText="name" label="Areas" validate={[required()]} />
        </ReferenceArrayInput>
        <SelectInput
          source="status"
          validate={[required()]}
          defaultValue={"open"}
          choices={statusOptions}
        />
        <TextInput source="lastGPXFilename" label="Current GPX file" disabled />
        <FileInput source="gpx" label="GPX file" accept=".gpx">
          <FileField source="src" title="title" />
        </FileInput>
        <DateInput
          disabled
          source="createdAt"
          formClassName={classes.inlineBlock}
        />
        <DateInput
          disabled
          source="updatedAt"
          formClassName={classes.inlineBlock}
        />
      </SimpleForm>
    </Edit>
  );
};
