import { makeStyles } from '@mui/styles';
import * as React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput, DateInput, SelectInput, required, email, minLength, regex, useRecordContext, useTranslate } from 'react-admin';
import {CustomToolbar} from './../../components/customtoolbar/customToolbar.component';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});



export const UserEdit = () => {
    const classes = useStyles();
    const record = useRecordContext();
    const translate = useTranslate();

    const validatePhone = regex(/^((((0{2}?)|(\+){1})46)|0)7[\d]{8}/, translate('resources.users.fields.validatePhone'));

    return (
        <Edit id={record?.id}>
            <SimpleForm toolbar={<CustomToolbar/>}>
                <BooleanInput source="isActive" />
                <TextInput disabled source="id" />
                {/*<ImageInput source="pictures" label="Related pictures" accept="image/*" multiple={false}>
                    <ImageField source="src" title="title" />
                </ImageInput>*/}
                <TextInput source="firstName" validate={[required()]} formClassName={classes.inlineBlock}/>
                <TextInput source="lastName" validate={[required()]} formClassName={classes.inlineBlock}/>
                <TextInput source="email" validate={[required(), email()]}/>
                <TextInput source="phone" validate={[required(), validatePhone]} />
                <SelectInput source="role" validate={[required()]} defaultValue={'user'} choices={[
                    { id: 'user', name: 'resources.users.fields.roles.user'},
                    { id: 'groomer', name: 'resources.users.fields.roles.groomer' },
                    { id: 'admin', name: 'resources.users.fields.roles.admin' }
                ]} />
                <TextInput source="password" validate={[minLength(8)]}/>
                <DateInput disabled source="createdAt" formClassName={classes.inlineBlock}/>
                <DateInput disabled source="updatedAt" formClassName={classes.inlineBlock}/>
            </SimpleForm>
        </Edit>
    );
}