import { makeStyles } from '@mui/styles';
import * as React from "react";
import {
  DateInput,
  Edit,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
  TranslatableInputs
} from "react-admin";
import { CustomToolbar } from "../../components/customtoolbar/customToolbar.component";
import { PublishingStatus } from "../../shared/enums/publishing-status.enum";
import { locales } from '../../i18n/languges';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues } from 'react-hook-form';

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const TermsEdit = () => {
  const statusOptions = Object.keys(PublishingStatus).map((key) => {

    if (key === "published") {
      return {
        id: PublishingStatus[key],
        name: 'resources.enums.publishingStatus.ready',
      };
    } else {
      return {
        id: PublishingStatus[key],
        name: `resources.enums.publishingStatus.${key}`,
      }
    }
  });

  const classes = useStyles();

  const record = useRecordContext();

  const schema = yup
    .object()
    .shape({
      title: yup.object().shape({
        en: yup.string().required()
      }).required(),
      terms: yup.object().shape({
        en: yup.string().required()
      }).required(),
    });
  const resolver = yupResolver<FieldValues>(schema);


  return (
    <Edit id={record?.id}>
      <SimpleForm toolbar={<CustomToolbar />} resolver={resolver}>
        <SelectInput
          source="status"
          validate={[required()]}
          choices={statusOptions}
        />
        <TextInput source="name" validate={[required()]} fullWidth />
        <TranslatableInputs fullWidth={true} locales={locales} sx={{ marginBottom: '2rem' }} >
          <TextInput source="title" fullWidth />
          <TextInput
            source="terms"
            rows={8}
            multiline={true}
            fullWidth={true}
          />
        </TranslatableInputs>
        <DateInput
          disabled
          source="createdAt"
          formClassName={classes.inlineBlock}
        />
        <DateInput
          disabled
          source="updatedAt"
          formClassName={classes.inlineBlock}
        />
      </SimpleForm>
    </Edit>
  );
};
