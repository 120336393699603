import * as React from "react";
import { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { BulkDeleteButton, BulkDeleteWithConfirmButton, Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import { List, Datagrid, TextField, EmailField, BooleanField, DateField, ArrayField, SingleFieldList, ChipField, useLocaleState } from 'react-admin';
import { PathCategoryEdit } from "./pathCategory.edit.component";

const PathCategoryFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
    </Filter>
);

export const PathCategoryList = () => {
    const [locale] = useLocaleState();

    return (
        <List
            filters={<PathCategoryFilter />}
            exporter={false}
            actions={false}
        >
            <Datagrid rowClick="edit" expand={<PathCategoryEdit/>} bulkActionButtons={false}>
                <BooleanField source="isListed" />
                <TextField source={`name.${locale}`} label="resources.pathcategories.fields.name"/>
                <TextField source={`pageTitle.${locale}`} label="resources.pathcategories.fields.pageTitle" />
                <DateField source="createdAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
                <DateField source="updatedAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
            </Datagrid>
        </List>
    );
}