import * as React from "react";
import { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { BulkDeleteWithConfirmButton, Confirm, Filter, NullableBooleanInput, TextInput, useNotify, useRefresh, useUnselectAll, useUpdateMany, useTranslate, List, Datagrid, TextField, EmailField, BooleanField, DateField } from 'react-admin';
import { UserEdit } from "./user.edit.component";

const useStyles = makeStyles((theme: any) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const UserBulkActionButtons = (props:any) => {
    const [selectedIds, setSelectedIds] = React.useState(props.selectedIds);
    const [isActive, setIsActive] = React.useState('');

    const [openActiveConfirm, setOpenActiveConfirm] = React.useState(false);

    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('users');
    const translate = useTranslate();

    const [updateManyWithActive, { isLoading: loadingManyWithActive }] = useUpdateMany(
        'users',
        {
            ids: selectedIds,
            data: { isActive },
        },
        {
            onSuccess: () => {
                refresh();
                notify('Users updated');
                unselectAll();
            },
            onError: error => notify(`Error: paths failed to update active state.`, {type: 'warning'}),
        }
    );

    const handleActiveDialogClose = () => setOpenActiveConfirm(false);

    const handleConfirmForActive = () => {
        updateManyWithActive();
        setOpenActiveConfirm(false);
    };

    React.useEffect(() => {
        setIsActive('');
        setSelectedIds(props.selectedIds);
    }, [props.selectedIds, selectedIds]);

    const handleChange = (event: any) => {
        setOpenActiveConfirm(true);
        setIsActive(event.target.value);
    };

    return (
        <Fragment>
            <FormControl variant="standard">
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">{translate(`resources.users.setActive`)}</InputLabel>
                <Select
                    value={isActive}
                    onChange={handleChange}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        <em>{translate(`resources.users.selectOne`)}</em>
                    </MenuItem>
                    <MenuItem value="true">{translate(`resources.users.yes`)}</MenuItem>
                    <MenuItem value="false">{translate(`resources.users.no`)}</MenuItem>
                </Select>
                {/*<FormHelperText>Label + placeholder</FormHelperText>*/}
            </FormControl>
            <Confirm
                isOpen={openActiveConfirm}
                loading={loadingManyWithActive}
                title="Update paths with active state"
                content={`Are you sure you want set the active state to "${isActive}" for these items?`}
                onConfirm={handleConfirmForActive}
                onClose={handleActiveDialogClose}
            />
            <BulkDeleteWithConfirmButton {...props} />
        </Fragment>
    );
}

const UserFilter = props => (
    <Filter {...props}>
        <TextInput source="search" alwaysOn resettable/>
        <NullableBooleanInput source="isActive" nullLabel="All"/>
        {/*<ReferenceInput
            label="User"
            source="userId"
            reference="users"
            allowEmpty
        >
            <SelectInput optionText="email" />
        </ReferenceInput>*/}
    </Filter>
);

export const UserList = () => {
    return (
        <>
            <List
                exporter={false}
                filters={<UserFilter/>}
            >
                <Datagrid rowClick="edit" expand={<UserEdit/>} bulkActionButtons={<UserBulkActionButtons/>}>
                    <TextField source="id" />
                    <BooleanField source="isActive" />
                    <TextField source="firstName" />
                    <TextField source="lastName" />
                    <EmailField source="email" />
                    {/*<TextField source="services" />
                    <TextField source="role" />*/}
                    <DateField source="createdAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
                    <DateField source="updatedAt" options={{ day: '2-digit', month: 'short', year: 'numeric' }} />
                    {/*<ArrayField source="userProducts"><SingleFieldList><ChipField source="id" /></SingleFieldList></ArrayField>*/}
                </Datagrid>
            </List>
        </>
    );
}