import { Datagrid, FunctionField, List, Loading, NumberField, TextField, TopToolbar, useGetList, useGetOne, useRecordContext, useLocaleState, useTranslate } from "react-admin";
import { Link, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import { StartPageRowEntryEdit } from "./startPageRowEntry.edit.component";
import { EntryType } from "../../../../shared/enums/start-page.enum";

const useStyles = makeStyles({
    emptyRow: { display: "flex", marginTop: "32px", justifyContent: "center"},
});

const getSourceForType = (type: string) => {
    switch (type) {
        case EntryType.Path:
            return 'paths';

        case EntryType.Poi:
            return 'pois';

        case EntryType.PathCategory:
            return 'pathCategories';

        case EntryType.PoiCategory:
            return 'poiCategories';

        case EntryType.Aoi: 
            return 'aois';
        
        case EntryType.AoiCategory:
            return 'aoiCategories';

        default:
            return '';
    }
};

const CustomCreateButton = (props: any) => {
    const { rowId } = useParams();
    const translate = useTranslate();

    return (
        <Button
            {...props}
            component={Link}
            to={`/startpages/rows/${rowId}/entries/create`}
            startIcon={<AddIcon/>}
        >
            {translate('resources.startpages.create')}
        </Button>
    )
};

const CustomActions = () => {
    return (
        <TopToolbar>
            <CustomCreateButton/>
        </TopToolbar>
    )
};

const NameField = (props: any) => {
    const record = useRecordContext();
    const entry = useGetOne(getSourceForType(record.entryType), { id: record.entryId });
    const [locale] = useLocaleState();

    return <FunctionField {...props} render={record => {
        if (entry.isLoading) {
            return '';
        }

        return entry?.data?.name[locale];
    }}/>;
};

export const StartPageRowEntryList = () => {
    const { rowId } = useParams();
    const classes = useStyles();

    const list = useGetList("startpages/entries", {
        filter: { rowId },
    });

    if (list.isLoading) {
        return <Loading></Loading>;
    }

    return (
        <>
            <List resource="startpages/entries" filter={{ rowId }} actions={<CustomActions/>}>
                <Datagrid rowClick="edit" expand={<StartPageRowEntryEdit/>}>
                    <TextField source="entryType" label="resources.startpages.fields.name"/>
                    <NameField label="resources.startpages.fields.name"/> 
                    <NumberField source="priority"/>
                </Datagrid>
            </List>
            { list?.data?.length === 0 && (
                <div className={classes.emptyRow}>
                    <CustomCreateButton variant="contained" size="small"/>
                </div>
            )}
        </>
    );
};