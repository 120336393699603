import * as React from "react";
import {
  Edit,
  TextInput,
  DateInput,
  required,
  BooleanInput,
  SimpleForm,
  ImageInput,
  ImageField,
  useRecordContext,
  TranslatableInputs
} from "react-admin";
import CustomToolbar from "../../components/customtoolbar/customToolbar.component";
import {locales} from  '../../i18n/languges';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues } from 'react-hook-form';

export const AreaOfInterestCategoryEdit = () => {
  const record = useRecordContext();
  const schema = yup
    .object()
    .shape({
      name: yup.object().shape({
        en: yup.string().required()
      }).required(),
      pageTitle: yup.object().shape({
        en: yup.string().required()
      }).required(),
    });
  const resolver = yupResolver<FieldValues>(schema);
  
  return (
    <Edit id={record?.id}>
      <SimpleForm
        toolbar={<CustomToolbar noDelete={true} />}
        resolver={resolver}
      >
        <BooleanInput source="isListed" validate={[required()]} fullWidth />
        <TranslatableInputs fullWidth={true} locales={locales} sx={{marginBottom: '2rem'}}>
          <TextInput source="name" fullWidth />
          <TextInput source="pageTitle" fullWidth />
          <TextInput source="description" rows={8} multiline fullWidth />
        </TranslatableInputs>
        <ImageInput source="media" multiple={false}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <DateInput disabled source="createdAt" fullWidth />
        <DateInput disabled source="updatedAt" fullWidth />
      </SimpleForm>
    </Edit>
  );
};
