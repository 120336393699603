import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title, useTranslate } from 'react-admin';

export const Dashboard = ({ permissions }) => {
    const translate = useTranslate();
    return (
        <Card>
            <Title title={translate('ra.page.dashboard')} />
            <CardContent>
                <h1>{translate('ra.page.dashboard')}</h1>

                {permissions === 'groomer'
                    ? <p>{translate('resources.page.dashboard.welcomeGroomer')}</p>
                    : null
                }
                {permissions === 'admin'
                    ? <p>{translate('resources.page.dashboard.welcomeAdmin')}</p>
                    : null
                }
            </CardContent>
        </Card>
    )
}

export default Dashboard;