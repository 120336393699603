import * as React from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  Filter,
  FunctionField,
  List,
  ReferenceArrayField,
  ReferenceInput,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
  useLocaleState
} from "react-admin";
import { AoiEdit } from "./aoi.edit.component";
import { startCase, toLower } from "lodash";
import { DisplayStatus } from "../../shared/enums/display-status.enum";

const AoiFilter = (props) => {
  const {locale} = props;

  return (
    <Filter {...props}>
      <TextInput
        source="search"
        alwaysOn
        resettable
      />
      <ReferenceInput
        source="areaOfInterestCategoryId"
        target="id"
        reference="aoicategories"
        alwaysOn
      >
        <SelectInput variant="filled" optionText={`name.${locale}`} label="resources.aois.fields.category" emptyText={'resources.aois.filterText'} />
      </ReferenceInput>
    </Filter>
)};

export const AoiList = () => {
  const [locale] = useLocaleState();

  const getDisplay = (record) => {
    if (record.display === DisplayStatus.Scheduled && record.timePeriod) {
      return `${startCase(toLower(record.display))} (${record.timePeriod.name})`;
    }
    return startCase(toLower(record.display));
  };

  return (
    <List filters={<AoiFilter locale={locale}/>} exporter={false}>
      <Datagrid rowClick="edit" expand={<AoiEdit/>}>
        <TextField 
          source={`name.${locale}`} 
          label="resources.aois.fields.name"
        />
        <TextField
          source={`areaOfInterestCategory.name.${locale}`}
          label="resources.aois.fields.category"
          sortable={false}
        />

        <ReferenceArrayField 
          source="area_ids"
          reference="areas"
          sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField color="secondary" source={`name.${locale}`} />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField
          source="display"
          render={(record) => getDisplay(record)}
        />
      </Datagrid>
    </List>
  );
};
