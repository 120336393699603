import * as React from "react";
import { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  usePermissions,
  useSidebarState,
} from "react-admin";
// @ts-ignore
import classnames from "classnames";
import { makeStyles } from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import List from "@mui/material/List";
import SubMenu from "../subMenu/subMenu.component";

// Icons
import DefaultIcon from "@mui/icons-material/ViewList";
import UserIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import RoomIcon from "@mui/icons-material/Room";
import ExploreIcon from "@mui/icons-material/Explore";
import LabelIcon from "@mui/icons-material/Label";
import InfoIcon from "@mui/icons-material/Info";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FilterListIcon from "@mui/icons-material/FilterList";
import StarIcon from "@mui/icons-material/Star";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import GavelIcon from "@mui/icons-material/Gavel";
import MapIcon from "@mui/icons-material/Map";
import TerrainIcon from "@mui/icons-material/Terrain";

const useStyles = makeStyles(
  (theme: any) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: "0.5em",
      [theme.breakpoints.only("xs")]: {
        marginTop: 0,
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "1.5em",
      },
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
  { name: "RaMenu" }
);


const Menu: FC<MenuProps> = (props) => {
  const {
    classes: classesOverride,
    className,
    dense,
    hasDashboard,
    onMenuClick,
    logout,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const isXSmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down("xs")
  );
  const [open] = useSidebarState();
  const { permissions } = usePermissions();

  const translateMenu = (labelKey: string) => translate(`resources.menu.${labelKey}`);

  // Used to force redraw on navigation
  // useSelector((state: ReduxState) => state.router.location.pathname);

  return (
    <List
      className={classnames(classes.main, className)}
      component="nav"
      {...rest}
    >
      {hasDashboard && (
        <DashboardMenuItem
          onClick={onMenuClick}
          dense={dense}
          sidebarIsOpen={open}
        />
      )}
      {/*resources
                .filter(r => r.hasList)
                .map(resource => (
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={translatedResourceName(
                            resource,
                            translate
                        )}
                        leftIcon={
                            resource.icon ? <resource.icon /> : <DefaultIcon />
                        }
                        onClick={onMenuClick}
                        dense={dense}
                        sidebarIsOpen={open}
                    />
                    ))*/}
      {permissions === "admin" && (
        //@ts-ignore
        <MenuItemLink to="/users"
          primaryText={translateMenu('users')}
          leftIcon={<UserIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {permissions === "admin" && (
        <SubMenu
          text={translateMenu('appSettings')}
          icon={<SettingsIcon />}
          links={[
            { 
              text: translateMenu('settings'), 
              link: "/settings/1", 
              icon: <SettingsIcon /> 
            },
            {
              text: translateMenu('terms'),
              link: "/terms",
              icon: <GavelIcon />,
            },
            {
              text: translateMenu('timePeriods'),
              link: "/timeperiods",
              icon: <DateRangeIcon />,
            },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}
      {permissions === "admin" && (
        <SubMenu
          text={ translateMenu('startPage')}
          icon={<SettingsIcon />}
          links={[
            { text: translateMenu('startPage'), link: "/startpages/1", icon: <SettingsIcon /> },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}
      {permissions === "admin" && (
        //@ts-ignore
        <MenuItemLink
          to="/areas"
          primaryText={ translateMenu('areas')}
          leftIcon={<AccountTreeIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}

      {permissions === "admin" && (
        <SubMenu
          text={translateMenu('information')}
          icon={<InfoIcon />}
          links={[
            { text: translateMenu('information'), link: "/information", icon: <InfoIcon /> },
            {
              text: translateMenu('tags'),
              link: "/informationtags",
              icon: <LabelIcon />,
            },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}

      {permissions === "admin" && (
        <SubMenu
          text={translateMenu('areaOfInterests')}
          icon={<MapIcon />}
          links={[
            { text: translateMenu('areaOfInterests'), link: "/aois", icon: <RoomIcon /> },
            {
              text: translateMenu('categories'),
              link: "/aoicategories",
              icon: <DefaultIcon />,
            },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}
      {permissions === "admin" && (
        <SubMenu
          text={translateMenu('pointOfInterests')}
          icon={<MapIcon />}
          links={[
            { text: translateMenu('pointOfInterests'), link: "/pois", icon: <RoomIcon /> },
            {
              text: translateMenu('categories'),
              link: "/poicategories",
              icon: <DefaultIcon />,
            },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}
      {permissions === "admin" && (
        <SubMenu
          text={translateMenu('paths')}
          icon={<ExploreIcon />}
          links={[
            { text: translateMenu('paths'), link: "/paths", icon: <CallSplitIcon /> },
            {
              text: translateMenu('categories'),
              link: "/pathcategories",
              icon: <DefaultIcon />,
            },
            // { text: translateMenu('areas'), link: '/pathsAreas', icon: <AccountTreeIcon /> },
            { text: translateMenu('tags'), link: "/tags", icon: <LabelIcon /> },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}
      {permissions === "admin" && (
        <SubMenu
          text={translateMenu('snowmobiles')}
          icon={<ExploreIcon />}
          links={[
            {
              text: translateMenu('recommendations'),
              link: "/snowmobile/trails",
              icon: <StarIcon />,
            },
            {
              text: translateMenu('subPaths'),
              link: "/snowmobile/subpaths",
              icon: <LinearScaleIcon />,
            },
            {
              text: translateMenu('freeRidingAreas'),
              link: "/snowmobile/fras",
              icon: <ControlCameraIcon />,
            },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}
      {/* NOTE: Grooming menu */}
      {(permissions === "admin" || permissions === "groomer") && (
        <SubMenu
          text={translateMenu('grooming')}
          icon={<TerrainIcon />}
          links={[
            ...(permissions === "admin"
              ? [
                  {
                    text: translateMenu('groups'),
                    link: "/grooming/filter/paths",
                    icon: <FilterListIcon />,
                  },
                ]
              : []),
            {
              text: translateMenu('crossCountryPaths'),
              link: "/grooming/paths",
              icon: <CallSplitIcon />,
            },
            {
              text: translateMenu('snowmobilePaths'),
              link: "/grooming/snowmobile/subpaths",
              icon: <LinearScaleIcon />,
            },
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}
      {/* NOTE: Common menu */}
      {/*<MenuItemLink
                to="/help"
                primaryText="Help"
                leftIcon={<HelpIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />*/}
      {isXSmall && logout}
    </List>
  );
};

export interface MenuProps {
  classes?: object;
  className?: string;
  dense?: boolean;
  hasDashboard?: boolean;
  logout?: ReactNode;
  onMenuClick?: () => void;
}

Menu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
  onMenuClick: () => null,
};

export default Menu;
