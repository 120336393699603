import { makeStyles } from '@mui/styles';
import * as React from "react";
import { Create, SimpleForm, TextInput, BooleanInput, SelectInput, required, email, minLength, regex, useTranslate } from 'react-admin';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});



export const UserCreate = () => {
    const classes = useStyles();
    const translate = useTranslate();
    
    const validatePhone = regex(/^((((0{2}?)|(\+){1})46)|0)7[\d]{8}/, translate('resources.users.fields.validatePhone'));

    return (
        <Create>
            <SimpleForm>
                <BooleanInput source="isActive" defaultValue={true} />
                <TextInput source="firstName" validate={[required()]} formClassName={classes.inlineBlock}/>
                <TextInput source="lastName" validate={[required()]} formClassName={classes.inlineBlock}/>
                <TextInput source="email" validate={[required(), email()]}/>
                <TextInput source="phone" validate={[required(), validatePhone]} />
                <TextInput source="password" validate={[required(), minLength(8)]}/>
                <SelectInput source="role" validate={[required()]} defaultValue={'user'} choices={[
                    { id: 'user', name: 'resources.users.fields.roles.user'},
                    { id: 'groomer', name: 'resources.users.fields.roles.groomer' },
                    { id: 'admin', name: 'resources.users.fields.roles.admin' },
                ]} />
            </SimpleForm>
        </Create>
    );
}