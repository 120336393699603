import { fetchUtils } from "react-admin";
import simpleRestProvider from "./simple-rest-provider";
import inMemoryJWT from "./inMemoryJWT";

const apiUrl = process.env.REACT_APP_API_URL;
const apiAuth = process.env.REACT_APP_API_AUTH;

export const httpClient = (url, opt = {}) => {
  const options = {
    ...opt,
    headers: new Headers({ Accept: "application/json" }),
  };
  const token = inMemoryJWT.getToken();

  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
  } else {
    inMemoryJWT.setRefreshTokenEndpoint(`${apiAuth}/refresh-token-admin`);
    return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
      if (gotFreshToken) {
        options.headers.set(
          "Authorization",
          `Bearer ${inMemoryJWT.getToken()}`
        );
      }
      return fetchUtils.fetchJson(url, options);
    });
  }
};

export const dataProvider = simpleRestProvider( apiUrl ?? '', httpClient);
