import * as React from "react";
import { Fragment } from "react";

const TranslationInputs = ({ children, variant }: { children?: any, variant?: string}) => {

    return (
        <Fragment>
            <h3>English version</h3>
            {React.Children.map(children,
                child => {
                    return React.cloneElement(child,
                        { variant: variant ?? 'outlined' }, null);
                })}
        </Fragment>
    );
};

export default TranslationInputs;
