import * as React from "react";
import { Filter, TextInput } from "react-admin";
import { List, Datagrid, TextField, DateField, useLocaleState } from "react-admin";
import { InformationTagEdit } from "./information-tag.edit.component";

const InformationTagListFilter = (props) => (
  <Filter {...props}>
    <TextInput
      source="search"
      alwaysOn
      resettable
    />
  </Filter>
);

export const InformationTagList = () => {
  const [locale] = useLocaleState();

  return (
    <List filters={<InformationTagListFilter />} exporter={false}>
      <Datagrid rowClick="edit" expand={<InformationTagEdit/>}>
        <TextField source={`name.${locale}`} label="resources.informationtags.fields.name" />
        <DateField
          source="createdAt"
          options={{ day: "2-digit", month: "short", year: "numeric" }}
        />
        <DateField
          source="updatedAt"
          options={{ day: "2-digit", month: "short", year: "numeric" }}
        />
      </Datagrid>
    </List>
  );
};
