import * as React from "react";
import { DateInput, Edit, required, SimpleForm, TextInput, useRecordContext } from "react-admin";

export const TimePeriodEdit = () => {
  const validateTimePeriodEdit = (values) => {
    const errors: any = {};

    if (
      new Date(values.end).toLocaleDateString("sv-SE") <
      new Date(values.start).toLocaleDateString("sv-SE")
    ) {
      errors.end = `"End" can't be before "Start"`;
    }

    return errors;
  };

  const record = useRecordContext();

  return (
    <Edit id={record?.id}>
      <SimpleForm
        validate={validateTimePeriodEdit}
      >
        <TextInput source="name" validate={[required()]} fullWidth />
        <DateInput
          source="start"
          defaultValue={new Date()}
          validate={[required()]}
        />
        <DateInput
          source="end"
          defaultValue={new Date()}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};
