import { defaultTheme } from 'react-admin';

export const darkTheme = {
    palette: {
        ...defaultTheme.palette,
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#FBBA72',
        },
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
    components: {
        ...defaultTheme.components,
        MuiAppBar: {
            styleOverrides: {
                colorSecondary: {
                    color: '#ffffffb3',
                    backgroundColor: '#616161e6',
                },
            },
        },
    },
};

export const lightTheme = {
    ...defaultTheme,
    palette: {
        primary: {
            main: '#4caf50',
            contrastText: '#fff',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
        background: {
            default: '#fcfcfe',
        },
    },
    typography: {
        ...defaultTheme.typography,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    shape: {
        ...defaultTheme.shape,
        borderRadius: 10,
    },
    components: {
        ...defaultTheme.components,
        RaLayout: {
            styleOverrides: {
                root: {
                    "& .RaLayout-content": {
                        paddingTop: '24px',
                        paddingLeft: '24px',
                    },
                },
            },
        },
        RaSidebar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    borderRight: '1px solid #e0e0e3',
                    marginRight: '24px',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    backgroundColor: '#4caf50',
                },
            },
        },
        RaAutocompleteArrayInput: {
            styleOverrides: {
                root: {
                    "& .RaAutocompleteArrayInput-chip": {
                        backgroundColor: '#4caf50',
                    }
                },
            },
        },
        RaDatagrid: {
            styleOverrides: {
                root: {
                    "& .RaDatagrid-rowOdd": {
                        backgroundColor: '#fafafa'
                    },
                },
            },
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    '&& > .MuiSvgIcon-root': {
                        marginLeft: 'auto',
                    },
                    // borderLeft: '3px solid #fff',
                    "& .RaMenuItemLink-active": {
                        color: '#4caf50',
                        '&& .MuiListItemIcon-root': {
                            color: '#4caf50',
                        }
                        // borderLeft: '3px solid #4f3cc9',
                    },
                },
            },
        },
        MuiCollapse: {
            styleOverrides: {
                root: {
                    background: '#fafafa',
                    borderTop: '1px solid #e0e0e3',
                    borderBottom: '1px solid #e0e0e3',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: 'none',
                },
                root: {
                    border: '1px solid #e0e0e3',
                    backgroundClip: 'padding-box',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    backgroundColor: '#4caf50',
                    color: '#fff',
                    boxShadow: 'none',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorSecondary: {
                    color: '#808080',
                    backgroundColor: '#fff',
                },
            },
        },
        RaFileInput: {
            styleOverrides: {
                root: {
                    "& .RaFileInput-dropZone": {
                        backgroundColor: '#fcfcfe !important',
                        border: '1px solid #eaeaea',
                        borderRadius: '10px'
                    },
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#f5f5f5',
                },
                barColorPrimary: {
                    backgroundColor: '#d7d7d7',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                contained: {
                    marginBottom: '5px',
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    '&$disabled': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    minWidth: '256px',
                },
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'outlined' as const,
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined' as const,
            },
        },
        MuiSelectInput: {
            defaultProps: {
                variant: 'outlined' as const,
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    justifyContent: 'space-between',
                },
            },
        },
        RaBulkActionsToolbar: {
            styleOverrides: {
                root: {
                    "& .RaBulkActionsToolbar-toolbar": {
                        position: 'relative',
                        height: '64px',
                        minHeight: '64px',
                        bottom: 0,
                        transform: 'translateY(0)',
                    },
                    "& .RaBulkActionsToolbar-collapsed": {
                        position: 'relative',
                        height: 0,
                        minHeight: 0,
                        bottom: 0,
                        transform: 'translateY(0)',
                    },
                },
            },
        },
    },
};