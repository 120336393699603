import * as React from "react";
import {
  ArrayField,
  ChipField,
  Filter,
  FunctionField,
  ReferenceArrayField,
  SingleFieldList,
  TextInput,
  useLocaleState
} from "react-admin";
import { List, Datagrid, TextField, DateField } from "react-admin";
import { InformationEdit } from "./information.edit.component";
import { startCase } from "lodash";
import { MapConnection } from "./enums/map-connection.enum";

const InformationListFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search"
      source="q"
      alwaysOn
      resettable
    />
  </Filter>
);

export const InformationList = () => {
  const [locale] = useLocaleState();

  const getMapConnection = (record) => {
    if (record.mapConnection === MapConnection.None) {
      return "—";
    }
    if (record.mapConnection === MapConnection.PointOfInterest) {
      return record.pointOfInterest ? `POI (${record.pointOfInterest.name[locale]})` : 'POI';
    }
    if (record.mapConnection === MapConnection.AreaOfInterest) {
      return record.areaOfInterest ? `AOI (${record.areaOfInterest.name[locale]})` : 'AOI';
    }
  };

  return (
    <List filters={<InformationListFilter />} exporter={false}>
      <Datagrid rowClick="edit" expand={<InformationEdit/>}>
        <TextField
            source={`severity`} 
            label="resources.information.fields.severity"/>
        <TextField 
          source={`title.${locale}`} 
          label="resources.information.fields.title"
        />
        <FunctionField
          source="mapConnection"
          render={(record) => getMapConnection(record)}
        />
        <DateField
          source="startAt"
          options={{ day: "2-digit", month: "short", year: "numeric" }}
        />
        <DateField
          source="endAt"
          options={{ day: "2-digit", month: "short", year: "numeric" }}
        />
        <ReferenceArrayField
          source="tag_ids"
          reference="informationtags"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ChipField clickable={false} source={`name.${locale}`}color={"primary"} />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField
          source="status"
          render={(record) => startCase(record.status)}
        />
      </Datagrid>
    </List>
  );
};
