import * as React from "react";
import { Create, SimpleForm, TextInput, TranslatableInputs } from "react-admin";
import { locales } from '../../i18n/languges';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues } from 'react-hook-form';

export const InformationTagCreate = () => {

  const schema = yup
    .object()
    .shape({
      name: yup.object().shape({
        en: yup.string().required()
      }).required()
    });
  const resolver = yupResolver<FieldValues>(schema);

  return (
    <Create>
      <SimpleForm sanitizeEmptyValues resolver={resolver}>
        <TranslatableInputs fullWidth={true} locales={locales} sx={{ marginBottom: '2rem' }} >
          <TextInput source="name" fullWidth />
        </TranslatableInputs>
      </SimpleForm>
    </Create>
  );
};
