import * as React from "react";
import { CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
// Aoi
import { AoiCreate } from "./resources/aoi/aoi.create.component";
import { AoiEdit } from "./resources/aoi/aoi.edit.component";
import { AoiList } from "./resources/aoi/aoi.list.component";
// Aoi Categories
import { AreaOfInterestCategoryCreate } from "./resources/aoiCategory/aoiCategory.create.component";
import { AreaOfInterestCategoryEdit } from "./resources/aoiCategory/aoiCategory.edit.component";
import { AreaOfInterestCategoryList } from "./resources/aoiCategory/aoiCategory.list.component";
// Areas
import { AreaCreate } from "./resources/area/area.create.component";
import { AreaEdit } from "./resources/area/area.edit.component";
import { AreaList } from "./resources/area/area.list.component";
// Grooming Filter
import { FilterCreate } from "./resources/grooming/filter/filter.create.component";
import { FilterEdit } from "./resources/grooming/filter/filter.edit.component";
import { FilterList } from "./resources/grooming/filter/filter.list.component";
// Grooming compnents
import { GroomingPathList } from "./resources/grooming/path/path.list.component";
import { GroomingSubPathList } from "./resources/grooming/snowmobile/subPath/subPath.list.component";
// Information
import { InformationCreate } from "./resources/information/information.create.component";
import { InformationEdit } from "./resources/information/information.edit.component";
import { InformationList } from "./resources/information/information.list.component";
// Information tag
import { InformationTagCreate } from "./resources/informationTag/information-tag.create.component";
import { InformationTagEdit } from "./resources/informationTag/information-tag.edit.component";
import { InformationTagList } from "./resources/informationTag/information-tag.list.component";
// Paths
import { PathCreate } from "./resources/path/path.create.component";
import { PathEdit } from "./resources/path/path.edit.component";
import { PathList } from "./resources/path/path.list.component";
// Paths Category
import { PathCategoryCreate } from "./resources/pathCategory/pathCategory.create.component";
import { PathCategoryEdit } from "./resources/pathCategory/pathCategory.edit.component";
import { PathCategoryList } from "./resources/pathCategory/pathCategory.list.component";
// Poi
import { PoiCreate } from "./resources/poi/poi.create.component";
import { PoiEdit } from "./resources/poi/poi.edit.component";
import { PoiList } from "./resources/poi/poi.list.component";
// Point Of Interest Category
import { PointOfInterestCategoryCreate } from "./resources/pointOfInterestCategory/pointOfInterestCategory.create.component";
import { PointOfInterestCategoryEdit } from "./resources/pointOfInterestCategory/pointOfInterestCategory.edit.component";
import { PointOfInterestCategoryList } from "./resources/pointOfInterestCategory/pointOfInterestCategory.list.component";
// Snowmobile SubPath
import { SubPathCreate } from "./resources/snowmobile/subPath/subPath.create.component";
import { SubPathEdit } from "./resources/snowmobile/subPath/subPath.edit.component";
import { SubPathList } from "./resources/snowmobile/subPath/subPath.list.component";
// Snowmobile Trails
import { TrailCreate } from "./resources/snowmobile/trail/trail.create.component";
import { TrailEdit } from "./resources/snowmobile/trail/trail.edit.component";
import { TrailList } from "./resources/snowmobile/trail/trail.list.component";
// Snowmobile Free riding areas
import { FraCreate } from "./resources/snowmobile/freeRidingArea/fra.create.component";
import { FraEdit } from "./resources/snowmobile/freeRidingArea/fra.edit.component";
import { FraList } from "./resources/snowmobile/freeRidingArea/fra.list.component";
// Tag
import { TagCreate } from "./resources/tag/tag.create.component";
import { TagEdit } from "./resources/tag/tag.edit.component";
import { TagList } from "./resources/tag/tag.list.component";
// Time Period
import { TimePeriodCreate } from "./resources/timePeriod/timePeriod.create.component";
import { TimePeriodEdit } from "./resources/timePeriod/timePeriod.edit.component";
import { TimePeriodList } from "./resources/timePeriod/timePeriod.list.component";
// User
import { UserCreate } from "./resources/user/user.create.component";
import { UserEdit } from "./resources/user/user.edit.component";
import { UserList } from "./resources/user/user.list.component";
// Terms
import { TermsCreate } from "./resources/terms/terms.create.component";
import { TermsEdit } from "./resources/terms/terms.edit.component";
import { TermsList } from "./resources/terms/terms.list.component";
// Start Page
import { StartPageEdit } from "./resources/startPage/startPage.edit.component";
// Start Page Row
import { StartPageRowCreate } from "./resources/startPage/row/startPageRow.create.component";
import { StartPageRowEdit } from "./resources/startPage/row/startPageRow.edit.component";
import { StartPageRowList } from "./resources/startPage/row/startPageRow.list.component";
// Start Page Row Entry
import { StartPageRowEntryCreate } from "./resources/startPage/row/entry/startPageRowEntry.create.component";
import { StartPageRowEntryEdit } from "./resources/startPage/row/entry/startPageRowEntry.edit.component";
import { StartPageRowEntryList } from "./resources/startPage/row/entry/startPageRowEntry.list.component";
// Settings
import { SettingsEdit } from "./resources/settings/settings.edit.component";
// Help
import { Help } from './components/help/help.component';

const routes = (permissions: string) => (
    <>
        <CustomRoutes>
            <Route path="/help" element={<Help/>} />,
        </CustomRoutes>

        {/* NOTE: Admin resources */}
        {permissions === "admin" ? (
        <Resource
            name="users"
            list={UserList}
            edit={UserEdit}
            create={UserCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="timeperiods"
            list={TimePeriodList}
            edit={TimePeriodEdit}
            create={TimePeriodCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="aois"
            list={AoiList}
            edit={AoiEdit}
            create={AoiCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="aoicategories"
            list={AreaOfInterestCategoryList}
            edit={AreaOfInterestCategoryEdit}
            create={AreaOfInterestCategoryCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="terms"
            list={TermsList}
            edit={TermsEdit}
            create={TermsCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource name="settings" edit={SettingsEdit} />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="pois"
            list={PoiList}
            edit={PoiEdit}
            create={PoiCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="poicategories"
            list={PointOfInterestCategoryList}
            edit={PointOfInterestCategoryEdit}
            create={PointOfInterestCategoryCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="paths"
            list={PathList}
            edit={PathEdit}
            create={PathCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="pathcategories"
            list={PathCategoryList}
            edit={PathCategoryEdit}
            create={PathCategoryCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="areas"
            list={AreaList}
            edit={AreaEdit}
            create={AreaCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="tags"
            list={TagList}
            edit={TagEdit}
            create={TagCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource name="pathstags" edit={TagEdit} create={TagCreate} />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="snowmobile/trails"
            list={TrailList}
            edit={TrailEdit}
            create={TrailCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="snowmobile/subpaths"
            list={SubPathList}
            edit={SubPathEdit}
            create={SubPathCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="snowmobile/fras"
            list={FraList}
            edit={FraEdit}
            create={FraCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="information"
            list={InformationList}
            edit={InformationEdit}
            create={InformationCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="informationtags"
            list={InformationTagList}
            edit={InformationTagEdit}
            create={InformationTagCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="grooming/filter/paths"
            list={FilterList}
            edit={FilterEdit}
            create={FilterCreate}
        />
        ) : null}
        {permissions === "admin" ? (
        <Resource
            name="startpages"
            edit={StartPageEdit}
        >
            <Route path=":pageId/rows" element={<StartPageRowList/>} />
            <Route path=":pageId/rows/create" element={<StartPageRowCreate/>} />
            <Route path="rows/:rowId" element={<StartPageRowEdit/>} />
            <Route path="rows/:rowId/entries" element={<StartPageRowEntryList/>} />
            <Route path="rows/:rowId/entries/create" element={<StartPageRowEntryCreate/>} />
            <Route path="entries/:entryId" element={<StartPageRowEntryEdit/>} />
        </Resource>
        ) : null}

        {/* NOTE: Grooming resources */}
        {permissions === "admin" || permissions === "groomer" ? (
        <Resource name="grooming/paths" list={GroomingPathList} />
        ) : null}
        {permissions === "admin" || permissions === "groomer" ? (
        <Resource
            name="grooming/snowmobile/subpaths"
            list={GroomingSubPathList}
        />
        ) : null}
        {permissions === "admin" || permissions === "groomer" ? (
        <Resource name="grooming/pathcategories" list={PathCategoryList} />
        ) : null}
        {permissions === "admin" || permissions === "groomer" ? (
        <Resource name="grooming/areas" list={AreaList} />
        ) : null}
        {permissions === "admin" || permissions === "groomer" ? (
        <Resource name="grooming/tags" list={TagList} />
        ) : null}
        {permissions === "admin" || permissions === "groomer" ? (
        <Resource name="grooming/pathstags" list={TagList} />
        ) : null}
        {permissions === "groomer" ? (
        <Resource name="grooming/filter/paths" list={FilterList} />
        ) : null}
    </>
);

export default routes;