import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  DateInput,
  Edit,
  ImageField,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
  TranslatableInputs,
  useTranslate
} from "react-admin";
import { Link } from "react-router-dom";
import { HeaderType } from "../../shared/enums/start-page.enum";
import { CustomToolbar } from "../../components/customtoolbar/customToolbar.component";
import { locales } from '../../i18n/languges';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues } from 'react-hook-form';

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

const EditRowsButton = () => {
  const record = useRecordContext();
  const translate = useTranslate();


  return (
    <Button
      component={Link}
      to={`/startpages/${record.id}/rows`}
      startIcon={<EditIcon/>}
    >
    {translate('resources.startpages.editRows')}
    </Button>
  );
};

const headerTypeOptions = Object.keys(HeaderType).map(key => ({
  id: HeaderType[key],
  name: `resources.enums.headerType.${HeaderType[key]}`,
}));

const schema = yup
  .object()
  .shape({
    title: yup.object().shape({
      en: yup.string().required()
    }).required(),
    body: yup.object().shape({
      en: yup.string().required()
    }).required()
});
const resolver = yupResolver<FieldValues>(schema);

export const StartPageEdit = () => {
  const classes = useStyles();

  return (
    <Edit redirect="/startpages/1">
      <SimpleForm
        toolbar={<CustomToolbar noDelete noRedirect />}
        resolver={resolver}
      >
        <TranslatableInputs fullWidth={true} locales={locales} sx={{ marginBottom: '2rem' }} >
          <TextInput source="title" formClassName={classes.inlineBlock}/>
          <TextInput source="body" fullWidth/>
        </TranslatableInputs>

        <SelectInput source="headerType" choices={headerTypeOptions} validate={[required()]}/>

        <ImageInput source="medias" accept="image/*" multiple={true}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <DateInput
          disabled
          source="updatedAt"
          formClassName={classes.inlineBlock}
        />

        <EditRowsButton/>
      </SimpleForm>
    </Edit>
  );
};
