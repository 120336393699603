import { makeStyles } from '@mui/styles';
import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
  ImageInput,
  ImageField,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import TranslationInputs from "../../../components/translationInputs/translationInputs.component";
import { Status } from "../../../shared/enums/status.enum";

const useStyles = makeStyles({
  inlineBlock: { display: "inline-flex", marginRight: "1rem" },
});

export const FraCreate = () => {
  const classes = useStyles();

  const statusOptions = Object.keys(Status).map((key) => ({
    id: Status[key],
    name: key,
  }));

  return (
    <Create>
      <SimpleForm sanitizeEmptyValues>
        <TextInput
          source="name"
          validate={[required()]}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source="description"
          rows={8}
          multiline={true}
          fullWidth={true}
        />
        <SelectInput
          source="status"
          validate={[required()]}
          defaultValue={"open"}
          choices={statusOptions}
        />
        <ReferenceArrayInput source="area_ids" reference="areas">
          <SelectArrayInput optionText="name" label="Areas" validate={[required()]} defaultValue={[]} />
        </ReferenceArrayInput>
        <FileInput
          source="geojson"
          label="Geo-Json file"
          accept=".geojson"
          validate={[required()]}
        >
          <FileField source="src" title="title" />
        </FileInput>

        <ImageInput source="medias" accept="image/*" multiple={true}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <TranslationInputs>
          <TextInput
            source="name_en"
            label="Name eng"
            formClassName={classes.inlineBlock}
          />
          <TextInput
            source="description_en"
            label="Description eng"
            rows={8}
            multiline={true}
            fullWidth={true}
          />
        </TranslationInputs>
      </SimpleForm>
    </Create>
  );
};
