import * as React from "react";
import { makeStyles } from '@mui/styles';
import { Edit, SimpleForm, TextInput, DateInput, required, useRecordContext, TranslatableInputs } from 'react-admin';
import TranslationInputs from "../../components/translationInputs/translationInputs.component";
import { CustomToolbar } from './../../components/customtoolbar/customToolbar.component';
import { locales } from  '../../i18n/languges';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues } from 'react-hook-form';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export const AreaEdit = () => {
    const classes = useStyles();
    const record = useRecordContext();
    const schema = yup
        .object()
        .shape({
            name: yup.object().shape({
                en: yup.string().required()
            }).required()
        });
    const resolver = yupResolver<FieldValues>(schema);

    return (
        <Edit id={record?.id}>
            <SimpleForm toolbar={<CustomToolbar/>} resolver={resolver}>
                <TranslatableInputs fullWidth={true} locales={locales} sx={{marginBottom: '2rem'}} >
                    <TextInput source="name" fullWidth/>
                </TranslatableInputs>
                <DateInput disabled source="createdAt" formClassName={classes.inlineBlock} />
                <DateInput disabled source="updatedAt" formClassName={classes.inlineBlock} />
            </SimpleForm>
        </Edit>
    );
}