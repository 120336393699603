import * as React from "react";
import {
  Create,
  DateInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";

const validateTimePeriodEdit = (values) => {
  const errors: any = {};

  if (
    new Date(values.end).toLocaleDateString("sv-SE") <
    new Date(values.start).toLocaleDateString("sv-SE")
  ) {
    errors.end = `"End" can't be before "Start"`;
  }

  return errors;
};

export const TimePeriodCreate = () => {
  return (
    <Create>
      <SimpleForm
        validate={validateTimePeriodEdit}
      >
        <TextInput source="name" validate={[required()]} fullWidth />
        <DateInput
          source="start"
          defaultValue={new Date()}
          validate={[required()]}
        />
        <DateInput
          source="end"
          defaultValue={new Date()}
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};
