import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { DeleteButton, Edit, ImageField, ImageInput, NumberInput, ReferenceInput, required, SaveButton, SelectInput, SimpleForm, Toolbar, useGetOne, useRecordContext, useLocaleState } from "react-admin";
import { useParams } from "react-router-dom";
import { EntryType } from "../../../../shared/enums/start-page.enum";

const useStyles = makeStyles({
    inlinBlock: { display: "inline-flex", marginRight: "1rem" },
});

const getSourceForType = (type: string) => {
    switch (type) {
        case EntryType.Path:
            return 'paths';

        case EntryType.Poi:
            return 'pois';

        case EntryType.PathCategory:
            return 'pathCategories';

        case EntryType.PoiCategory:
            return 'poiCategories';

        case EntryType.Aoi: 
            return 'aois';
    
        case EntryType.AoiCategory:
            return 'aoiCategories';

        default:
            return '';
    }
};

const EditToolbar = (props: {rowId: number}) => (
    <Toolbar>
        <SaveButton />
        <DeleteButton redirect={`/startpages/rows/${props.rowId}/entries`} />
    </Toolbar>
);

export const StartPageRowEntryEdit = () => {
    const classes = useStyles();
    const [referenceSource, setReferenceSource] = useState('');
    const [locale] = useLocaleState();
    const record = useRecordContext();
    const { entryId } = useParams();
    const id = record?.id ?? entryId;
    const entry = useGetOne("startpages/entries", {id});
    const rowId = entry?.data?.rowId;

    useEffect(() => {
            setReferenceSource(getSourceForType(entry?.data?.entryType));
    }, []);

    return (
        <Edit resource="startpages/entries" id={id} redirect={`/startpages/rows/${rowId}/entries`}>
            <SimpleForm toolbar={<EditToolbar rowId={rowId}/>}>
                <SelectInput source="entryType" validate={[required()]} onChange={e => setReferenceSource(getSourceForType(e.target.value))} choices={[
                    { name: 'Path', id: EntryType.Path },
                    { name: 'Poi', id: EntryType.Poi },
                    { name: 'Path Category', id: EntryType.PathCategory },
                    { name: 'Poi Category', id: EntryType.PoiCategory },
                    { name: 'Aoi', id: EntryType.Aoi },
                    { name: 'Aoi Category', id: EntryType.AoiCategory }
                ]}/>
                { referenceSource && (
                    <>
                        <ReferenceInput source="entryId" reference={referenceSource}>
                            <SelectInput optionText={`name.${locale}`} label="Category" validate={[required()]} />
                        </ReferenceInput>
                        <NumberInput source="priority"/>
                    </>
                )}

                <ImageInput source="media" accept="image/*" multiple={false}>
                    <ImageField source="src" title="title" />
                </ImageInput>

            </SimpleForm>
        </Edit>
    );
};